/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

body {
  margin: 0;
  font-family: var(--font-main);
}
header {
  height: var(--header-height);
  border-bottom-style: ridge;
}

::-webkit-scrollbar {
  scroll-behavior: smooth;
  height: 12px;
  width: 8px;
  padding-right: 5px;
  background: #00000000;
}

::-webkit-scrollbar-thumb {
  background: var(--shadow);
  -webkit-border-radius: 0.2ex;
}

#summaryColumns td {
  text-align: left;
}
app-nav-menu td:hover,
app-nav-menu td:focus {
  background: rgba(0, 0, 0, 0.4);
}
td {
  text-align: center;
}
.mat-sort-header-content {
  text-align: center;
  padding-bottom: 10px;
  align-items: baseline;
  display: block !important;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
p,
body,
span,
.mat-sort-header-content {
  font-size: var(--fz-1);
}
strong {
  user-select: none;
}
mat-date-range-input span {
  font-size: inherit;
}
