* {
  --pallete-1: #69e9d3;
  --pallete-2: #2079cf;
  --pallete-3: #ffda44;
  --pallete-4: #ff4e4b;
  --pallete-5: #656d5e;
  --pallete-6: #ff3366;
  --header-height: 65px;
  --header-color: #5e82ac;
  --table-color: #7b9ac9;
  // --header-color: #386641;
  // --table-color: #50915d;
  --primary: var(--pallete-1);
  --rating-stars-color: orange;
  --rating-stars-color-alt: green;
  --white: white;
  --white-offset: whitesmoke;
  --shadow: rgba(0, 0, 0, 0.089);
  --font-main: Roboto, "Helvetica Neue", sans-serif;
  --fz-1: 11px;
}
